// src/ContactForm.js
import React, { useState } from 'react';
import '../assets/styles/FormStyle.css'; // Ensure this path is correct

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send form data to an API)
    console.log('Form submitted:', formData);
  };

  return (
    <form action="" className="contact-form" onSubmit={handleSubmit}>
      <div className="form-wrapper">
        <label htmlFor="name" className="form-label">Name</label>
        <div className="input-wrapper">
          <input
            type="text"
            name="name"
            id="name"
            required
            placeholder="e.g John Doe"
            className="input-field"
            value={formData.name}
            onChange={handleChange}
          />
          <ion-icon name="person-circle"></ion-icon>
        </div>
      </div>

      <div className="form-wrapper">
        <label htmlFor="email" className="form-label">Email</label>
        <div className="input-wrapper">
          <input
            type="email"
            name="email"
            id="email"
            required
            placeholder="e.g johndoe@mail.com"
            className="input-field"
            value={formData.email}
            onChange={handleChange}
          />
          <ion-icon name="mail"></ion-icon>
        </div>
      </div>

      <div className="form-wrapper">
        <label htmlFor="phone" className="form-label">Phone</label>
        <div className="input-wrapper">
          <input
            type="tel"
            name="phone"
            id="phone"
            required
            placeholder="Phone Number"
            className="input-field"
            value={formData.phone}
            onChange={handleChange}
          />
          <ion-icon name="call"></ion-icon>
        </div>
      </div>

      <div className="form-wrapper">
        <label htmlFor="message" className="form-label">Message</label>
        <div className="input-wrapper">
          <textarea
            name="message"
            id="message"
            required
            placeholder="Write message..."
            className="input-field"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <ion-icon name="chatbubbles"></ion-icon>
        </div>
      </div>

      <button type="submit" className="btn btn-primary">Send</button>
    </form>
  );
}

export default ContactForm;
