import React from 'react';
import '../assets/styles/style.css';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <p className="h1 logo">
            <a href="#">
              Jack<span>.</span>
            </a>
          </p>
          <p className="copyright">
            &copy; 2024 <a href="#">gbpunmgx</a>. All rights reserved
          </p>
        </div>
      </footer>

      {/* Go to Top Button */}
      <a href="#top" className="go-top" data-go-top title="Go to Top">
        <h1>Go To Top</h1>
      </a>
    </>
  );
};

export default Footer;
