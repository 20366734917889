import React, { useState } from 'react';
import './assets/styles/style.css';
import heroBanner from './assets/images/hero-banner.png';
import heroBannerMd from './assets/images/hero-banner-md.png';
import heroBannerSm from './assets/images/hero-banner-sm.png';
import statIcon1 from './assets/images/stats-card_icon-1.png';
import statIcon2 from './assets/images/stats-card_icon-2.png';
import statIcon3 from './assets/images/stats-card_icon-3.png';
import aboutBanner from './assets/images/about-banner.png';
import project1 from './assets/images/project-1.png';
import project2 from './assets/images/project-2.png';
import project3 from './assets/images/project-3.png';
import project4 from './assets/images/project-4.png';
import project5 from './assets/images/project-5.png';
import project6 from './assets/images/project-6.png';
import project7 from './assets/images/project-7.png';
// Import IonIcon if using it

 function MainContent() {
 const [activeTab, setActiveTab] = useState('skills');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

   const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: ''
    });

    // Handler for input changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    // Handler for form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission, e.g., send data to an API
      console.log('Form submitted:', formData);
      // Clear form after submission if needed
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    };
  return (
    <main>
      <article className="container">

        {/* HERO */}
        <section className="hero" id="home">
          <figure className="hero-banner">
            <picture>
              <source srcSet={heroBanner} media="(min-width: 768px)" />
              <source srcSet={heroBannerMd} media="(min-width: 500px)" />
              <img src={heroBannerSm} alt="A man in a blue shirt with a happy expression" className="w-100" />
            </picture>
          </figure>
          <div className="hero-content">
            <h2 className="h2 hero-title">We Design & Build Creative Products</h2>
            <a href="#contact" className="btn btn-primary">Get in touch</a>
          </div>

          <a href="#stats" className="scroll-down">Scroll</a>
        </section>

        {/* STATS */}
        <section className="stats" id="stats">
          <ul className="stats-list">
            <li><a href="#" className="stats-card"><div className="card-icon"><img src={statIcon1} alt="Badge icon" /></div><h2 className="h2 card-title">12+ <strong>Years of Experience</strong></h2></a></li>
            <li><a href="#" className="stats-card"><div className="card-icon"><img src={statIcon2} alt="Checkmark icon" /></div><h2 className="h2 card-title">230+ <strong>Completed Projects</strong></h2></a></li>
            <li><a href="#" className="stats-card"><div className="card-icon"><img src={statIcon3} alt="Peoples rating icon" /></div><h2 className="h2 card-title">95+ <strong>Happy Clients</strong></h2></a></li>
          </ul>
        </section>

        {/* ABOUT */}
        <section className="about" id="about">
          <figure className="about-banner">
            <img src={aboutBanner} alt="A man in a alice blue shirt with a thinking expression" className="w-100" />
          </figure>
          <div className="about-content section-content">
            <p className="section-subtitle">About me</p>
            <h2 className="h3 section-title">Need a Creative Product? I can Help You!</h2>
            <p className="section-text">
              Hi! I’m Jack Reacher, and I’m a developer who has passion for building clean web applications with intuitive functionalities. I enjoy the process of turning ideas into reality using creative solutions. I’m always curious about learning new skills, tools, and concepts. In addition to working on various solo full stack projects, I have worked with creative teams, which involves daily stand-ups and communications, source control, and project management.
            </p>
            <div className="btn-group">
              <button className="btn btn-secondary">Hire me</button>
              <button className="btn btn-primary">Download cv</button>
            </div>
          </div>
        </section>

        {/* SKILLS */}
        <section className="skills" id="skills">
             <div className="skills-content section-content">
               <p className="section-subtitle">My skills</p>
               <h2 className="h3 section-title">What My Programming Skills Included?</h2>
               <p className="section-text">
                 I develop simple, intuitive and responsive user interface that helps users get things done with less effort
                 and time with those technologies.
               </p>
               <div className="skills-toggle">
                 <button
                   className={`toggle-btn ${activeTab === 'skills' ? 'active' : ''}`}
                   onClick={() => handleTabClick('skills')}
                 >
                   Skills
                 </button>
                 <button
                   className={`toggle-btn ${activeTab === 'tools' ? 'active' : ''}`}
                   onClick={() => handleTabClick('tools')}
                 >
                   Tools
                 </button>
               </div>
             </div>
             <div className="skills-box">
               {activeTab === 'skills' && (
                 <ul className="skills-list">
                   {/* Skills List */}
                   {[
                     { name: 'HTML5', imgSrc: './assets/images/html5.png' },
                     { name: 'CSS3', imgSrc: './assets/images/css3.png' },
                     { name: 'JavaScript', imgSrc: './assets/images/javascript.png' },
                     { name: 'TypeScript', imgSrc: './assets/images/typescript.png' },
                     { name: 'jQuery', imgSrc: './assets/images/jquery.png' },
                     { name: 'Bootstrap', imgSrc: './assets/images/bootstrap.png' },
                     { name: 'Angular', imgSrc: './assets/images/angular.png' },
                     { name: 'React', imgSrc: './assets/images/react.png' },
                     { name: 'Vue', imgSrc: './assets/images/vue.png' },
                     { name: 'Firebase', imgSrc: './assets/images/firebase.png' },
                     { name: 'PugJs', imgSrc: './assets/images/pugjs.png' },
                     { name: 'SASS', imgSrc: './assets/images/sass.png' }
                   ].map(skill => (
                     <li key={skill.name}>
                       <div className="skill-card">
                         <div className="tooltip">{skill.name}</div>
                         <div className="card-icon">
                           <img src={skill.imgSrc} alt={`${skill.name} logo`} />
                         </div>
                       </div>
                     </li>
                   ))}
                 </ul>
               )}
               {activeTab === 'tools' && (
                 <ul className="tools-list">
                   {/* Tools List */}
                   {[
                     { name: 'Ajax', imgSrc: './assets/images/ajax.png' },
                     { name: 'Gulp', imgSrc: './assets/images/gulp.png' },
                     { name: 'Webpack', imgSrc: './assets/images/webpack.png' },
                     { name: 'Git', imgSrc: './assets/images/git.png' },
                     { name: 'Npm', imgSrc: './assets/images/npm.png' },
                     { name: 'Command Line', imgSrc: './assets/images/command.png' },
                     { name: 'VS Code', imgSrc: './assets/images/vs-code.png' },
                     { name: 'Trello', imgSrc: './assets/images/trello.png' },
                     { name: 'ClickUp', imgSrc: './assets/images/clickup.png' },
                     { name: 'Slack', imgSrc: './assets/images/slack.png' },
                     { name: 'Photoshop', imgSrc: './assets/images/photoshop.png' },
                     { name: 'Adobe XD', imgSrc: './assets/images/adobe-xd.png' }
                   ].map(tool => (
                     <li key={tool.name}>
                       <div className="skill-card">
                         <div className="tooltip">{tool.name}</div>
                         <div className="card-icon">
                           <img src={tool.imgSrc} alt={`${tool.name} logo`} />
                         </div>
                       </div>
                     </li>
                   ))}
                 </ul>
               )}
             </div>
           </section>

        {/* PROJECTS */}
         <section className="project" id="portfolio">
              <ul className="project-list">
                <li>
                  <div className="project-content section-content">
                    <p className="section-subtitle">My Works</p>
                    <h2 className="h3 section-title">See My Works Which Will Amaze You!</h2>
                    <p className="section-text">
                      We develop the best quality website that serves for the long-term. Well-documented, clean, easy and
                      elegant interface helps any non-technical clients.
                    </p>
                  </div>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project1} className="w-100" alt="A macintosh on a yellow background." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 01</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project2} className="w-100" alt="On a Blue background, a Wacom and a mouse." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 02</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project3} className="w-100" alt="A Cassette tape on a mellow apricot background." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 03</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project4} className="w-100" alt="Blue digital watch on a dark liver background." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 04</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project5} className="w-100" alt="On a dark liver background, Airport luggage car carrying a luggage." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 05</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project6} className="w-100" alt="On a yellow background, a digital watch and a glass." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 06</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="project-card">
                    <figure className="card-banner">
                      <img src={project7} className="w-100" alt="A fujifilm instant camera on a dark electric blue background." />
                    </figure>
                    <div className="card-content">
                      <h3 className="h4 card-title">Lorem Ipsum Dolor 07</h3>
                      <time className="publish-date" dateTime="2022-04">April 2022</time>
                    </div>
                  </a>
                </li>

                <li>
                  <button className="load-more">Load more work</button>
                </li>
              </ul>
            </section>

        {/* CONTACT */}

          <section className="contact" id="contact">
              <div className="contact-content section-content">
                <p className="section-subtitle">Contact</p>
                <h2 className="h3 section-title">Have You Any Project? Please Drop a Message</h2>
                <p className="section-text">
                  Get in touch and let me know how I can help. Fill out the form and I’ll be in touch as soon as possible.
                </p>
                <ul className="contact-list">
                  <li className="contact-list-item">
                    <div className="contact-item-icon">
                      <ion-icon name="location-outline"></ion-icon>
                    </div>
                    <div className="wrapper">
                      <h3 className="h4 contact-item-title">Address:</h3>
                      <address className="contact-info">
                        941 Saqrqorish Road, alandalos, granada, wa 47122-4194
                      </address>
                    </div>
                  </li>
                  <li className="contact-list-item">
                    <div className="contact-item-icon">
                      <ion-icon name="call-outline"></ion-icon>
                    </div>
                    <div className="wrapper">
                      <h3 className="h4 contact-item-title">Phone:</h3>
                      <a href="tel:01234567789" className="contact-info">(01) 234 567 789</a>
                      <a href="tel:09765432200" className="contact-info">(09) 765 432 200</a>
                    </div>
                  </li>
                  <li className="contact-list-item">
                    <div className="contact-item-icon">
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    <div className="wrapper">
                      <h3 className="h4 contact-item-title">Email:</h3>
                      <a href="mailto:info@jack.com" className="contact-info">info@jack.com</a>
                      <a href="mailto:support@jack.com" className="contact-info">support@jack.com</a>
                    </div>
                  </li>
                  <li>
                    <ul className="contact-social-list">
                      <li>
                        <a href="#" className="contact-social-link">
                          <div className="tooltip">Facebook</div>
                          <ion-icon name="logo-facebook"></ion-icon>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="contact-social-link">
                          <div className="tooltip">Twitter</div>
                          <ion-icon name="logo-twitter"></ion-icon>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="contact-social-link">
                          <div className="tooltip">Linkedin</div>
                          <ion-icon name="logo-linkedin"></ion-icon>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="contact-social-link">
                          <div className="tooltip">Youtube</div>
                          <ion-icon name="logo-youtube"></ion-icon>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <form action="" className="contact-form" onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  <label htmlFor="name" className="form-label">Name</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      placeholder="e.g John Doe"
                      className="input-field"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <ion-icon name="person-circle"></ion-icon>
                  </div>
                </div>

                <div className="form-wrapper">
                  <label htmlFor="email" className="form-label">Email</label>
                  <div className="input-wrapper">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      placeholder="e.g johndoe@mail.com"
                      className="input-field"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <ion-icon name="mail"></ion-icon>
                  </div>
                </div>

                <div className="form-wrapper">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <div className="input-wrapper">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      required
                      placeholder="Phone Number"
                      className="input-field"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    <ion-icon name="call"></ion-icon>
                  </div>
                </div>

                <div className="form-wrapper">
                  <label htmlFor="message" className="form-label">Message</label>
                  <div className="input-wrapper">
                    <textarea
                      name="message"
                      id="message"
                      required
                      placeholder="Write message..."
                      className="input-field"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <ion-icon name="chatbubbles"></ion-icon>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">Send</button>
              </form>
            </section>

      </article>
    </main>
  );
}

export default MainContent;
