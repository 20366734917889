import React, { useState } from 'react';
import '../assets/styles/Header.css';

const Header = () => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <header className={`header ${theme}`} data-header>
      <div className="container">
        <h1 className="h1 logo">
          <a href="#">Jack<span>.</span></a>
        </h1>
        <nav className="navbar" data-navbar>
          <ul className="navbar-list">
            <li>
              <a href="#home" className="navbar-link">Home.</a>
            </li>
            <li>
              <a href="#about" className="navbar-link">About.</a>
            </li>
            <li>
              <a href="#skills" className="navbar-link">Skills.</a>
            </li>
            <li>
              <a href="#portfolio" className="navbar-link">Portfolio.</a>
            </li>
            <li>
              <a href="#contact" className="navbar-link">Contact.</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
