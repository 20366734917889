// src/App.js
import React from 'react';
import Header from './app/Header';
import MainContent from './MainContent';
import ContactSection from './app/ContactSection';
import ContactForm from './app/ContactForm';
import Footer from './app/Footer';

function App() {
  return (
    <div className="App">
    <Header/>
    <MainContent/>
    <Footer/>
    </div>
  );
}

export default App;
